.scrollable-group>.row {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
}

.scrollable-group>.row>.col-xs-4 {
  flex: 0 0 auto;
}

.city {
  word-wrap: break-word;
}

.form-control:focus {
  box-shadow: 0 0 0 0 rgba(40, 167, 69, 0.25);
}

.btn-custom {
  border-radius: 0.7em;
}

.btn-custom:hover {
  cursor: pointer;
}

.bg-scarlet {
  background-color: #63000d;
}

.bg-teal {
  background-color: #a296a8;
}

.bg-dark-grey {
  background-color: #262626;
}

.border-scarlet {
  border: 1px solid rgba(99, 0, 13, 0.2);
}

.border-teal {
  border: 1px solid rgba(162, 150, 168, 0.2);
}

.btn-custom:hover .border-scarlet {
  border: 1px solid #63000d;
  color: #63000d;
}

.btn-custom:hover .border-teal {
  border: 1px solid #a296a8;
  color: #a296a8;
}

.rounded-left {
  border-radius: 0.7em 0 0 0.7em;
  padding-left: 0.7em;
}

.rounded-right {
  border-radius: 0 0.7em 0.7em 0;
  padding-right: 0.7em;
}

.autocomplete-list {
  position: absolute;
  z-index: 99;
  left: 0;
}